<template>
  <div class="app-container">
    <div style="margin:5px 0px;">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="二级学科:">
          <el-input placeholder="请输入二级学科" v-model="search.secondMajor" size='small' class="form-line-item">
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left:10px;">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
            搜索
          </el-button>
          <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
            重置
          </el-button>
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="insertHandle">
                添加
            </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格 -->
    <div>
      <!-- <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;">
        <template #buttons>
          <el-button type="success" size="small" @click="singleInsert">单个导入</el-button>

          <el-upload style="display:inline-block;margin:0px 10px;" class="upload-demo" action="#"
            :on-change="handleChange" multiple :limit="1" :auto-upload="false" :show-file-list = 'false' :file-list="fileList">
            <el-button type="success" size="small">批量导入</el-button>
          </el-upload>

          <el-link type="primary" href='/static/学科.xlsx'>下载模板</el-link>
        </template>
      </vxe-toolbar> -->
      <!-- :loading="loading" -->
      <vxe-table :header-cell-style="headerCellStyle" round align="left" ref="schoolTable" highlight-current-row
        highlight-hover-row :keyboard-config="{isArrow: true}" class="mytable-scrollbar subject-table" keep-source
        :loading='loading' :data="paperList">
        <vxe-column type='seq' title="序号" width="10%" :show-overflow="'tooltip'" :tree-node='true'></vxe-column>
        <vxe-column field="majorType" title="门类" width="20%" :show-overflow="'tooltip'" :edit-render="{}" :formatter="fmMajorType">
        </vxe-column>
        <vxe-column field="firstMajor" title="一级学科" width="20%" :show-overflow="'tooltip'" :edit-render="{}">
        </vxe-column>
        <vxe-column field="secondMajor" title="二级学科" width="20%" :show-overflow="'tooltip'" :edit-render="{}">
        </vxe-column>
        <vxe-column field="secondMajorCode" title="学科代码" width="20%" :show-overflow="'tooltip'" :edit-render="{}">
        </vxe-column>
          <vxe-column title="操作">
              <template #default="{row,rowIndex,$rowIndex}">
                  <div >
                      <el-button size="mini" type="text" @click="deleteHandle(row)">删除
                      </el-button>
                  </div>

              </template>
          </vxe-column>
      </vxe-table>
        <vxe-pager perfect align='right' :current-page.sync="page.pageIndex" :page-size.sync="page.pageSize"
                   :total="page.total" :page-sizes="page.pageSizes"
                   :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                   @page-change="handlePageChange">
            <template #left>
                <vxe-button size="small" @click="firstPage">首页
                </vxe-button>
            </template>
        </vxe-pager>



        <vxe-modal v-model="showAdd" :position="{top: 5}" width="500" height="350" :show-footer='true' show-zoom resize
                   :transfer='true' @hide="insertSubjectModelHideHandle" >
            <template #title>
                添加学科信息
            </template>
            <vxe-form ref="subAddRef" :rules="insertSubjectRules"  :data="subjectAdd" title-align="right" title-width="100">
                <vxe-form-item field="firstMajor" title="一级学科" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input  v-model="data.firstMajor" placeholder="请输入一级学科" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="secondMajor" title="二级学科" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input  v-model="data.secondMajor" placeholder="请输入二级学科" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="majorType" title="学科类型" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <!--                        <vxe-input  v-model="data.majorType" placeholder="请输入学科类型" type="text">-->
                        <!--                        </vxe-input>-->
                        <vxe-select v-model="data.majorType" placeholder="可清除" clearable>
                            <vxe-option v-for="(item,idx) in majorTypeList" :key="idx" :value="item.value" :label="item.label"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="secondMajorCode" title="学科代码" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input  v-model="data.secondMajorCode" placeholder="请输入学科代码" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>

            </vxe-form>

            <template #footer>
                <el-button @click="cfmInsertSubjectHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>

<!--      <vxe-modal v-model="showEdit" :title="isEdit ? '编辑&保存' : '新增&保存'" width="800" min-width="600" min-height="300"-->
<!--        :loading="submitLoading" resize destroy-on-close>-->
<!--        <template #default>-->
<!--          <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent">-->
<!--            <vxe-form-item field="category" title="门类" :span="12" :item-render="{}">-->
<!--              <template #default="{ data }">-->
<!--                <vxe-input v-model="data.category" placeholder="请输入门类"></vxe-input>-->
<!--              </template>-->
<!--            </vxe-form-item>-->
<!--            <vxe-form-item field="firstMajor" title="一级学科" :span="12" :item-render="{}">-->
<!--              <template #default="{ data }">-->
<!--                <vxe-input v-model="data.firstMajor" placeholder="请输入一级学科"></vxe-input>-->
<!--              </template>-->
<!--            </vxe-form-item>-->
<!--            <vxe-form-item field="secondMajor" title="二级学科" :span="12" :item-render="{}">-->
<!--              <template #default="{ data }">-->
<!--                <vxe-input v-model="data.secondMajor" placeholder="请输入二级学科"></vxe-input>-->
<!--              </template>-->
<!--            </vxe-form-item>-->
<!--            <vxe-form-item field="secondMajorCode" title="学科代码" :span="12" :item-render="{}">-->
<!--              <template #default="{ data }">-->
<!--                <vxe-input v-model="data.secondMajorCode" placeholder="请输入学科代码"></vxe-input>-->
<!--              </template>-->
<!--            </vxe-form-item>-->
<!--            <vxe-form-item align="center" title-align="left" :span="24">-->
<!--              <template #default>-->
<!--                <vxe-button type="submit" @click='confirmInsert'>提交</vxe-button>-->
<!--                <vxe-button type="reset" @click='resetInsert'>重置</vxe-button>-->
<!--              </template>-->
<!--            </vxe-form-item>-->
<!--          </vxe-form>-->
<!--        </template>-->
<!--      </vxe-modal>-->
    </div>
  </div>
</template>

<script>
  // import XLSX from 'xlsx'
  export default {
    name: 'subjectManage',
    data() {
      return {
        loading: false,
        search: {
          secondMajor: '',
        },
        universityList: ['南京理工大学'],
        //论文列表
        paperList: [{
          id: '1',
          category: '工学',
          firstMajor: '电子信息',
          secondMajor: '计算机技术',
          secondMajorCode: '845100'
        }],
        fileList: [],
        formData: {
          category: '工学',
          firstMajor: '电子信息',
          secondMajor: '计算机技术',
          secondMajorCode: '845100'
        },
        //分页
        page: {
          currentPage: 1,
          pageSize: 10,
          pageCount: 40, //自己用
          pageSizes: [1, 5, 10, 15, 20],
          total: 0
        },
        //弹出框
        submitLoading: false,
        showEdit: false,
        isEdit: true,
          majorTypeList:[
              {
                  label: '专硕',
                  value: 0
              },
              {
                  label: '学硕',
                  value: 1
              },
              {
                  label: '本科',
                  value: 2
              },

          ],
          showAdd:false,
          insertSubjectRules:{
              firstMajor: [
                  { required: true, message: '请输入一级学科' }
              ],
              secondMajor: [
                  { required: true, message: '请输入二级学科' }
              ],
              secondMajorCode: [
                  { required: true, message: '请输入学科代码' }
              ],
              majorType: [
                  { required: true, message: '请输入学科类型' }
              ]
          },
          subjectAdd:{
              firstMajor:'',
              secondMajor:'',
              secondMajorCode:'',
              majorType:''
          }

      }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyPress);
      this.getPaperList ();

    },
    methods: {
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.batchSearch();
            }
        },

        //获取所有学科信息
      getPaperList () {
        this.loading = true;
        let params = {
          "firstMajor": "",
          "secondMajorCode": "",
          "secondMajor": this.search.secondMajor,
          "majorType": "",
          "pageNum": this.page.currentPage,
          "pageSize": this.page.pageSize
        }
        console.log(params)
        this.$api.basicInfo.getSubjectList(params)
          .then(res => {
              console.log(res.data.data);
            this.paperList = res.data.data;
            this.page.total = res.data.count;
            this.loading = false;
          })
        // this.$axios.get("/course/get_course").then(res => {
        //   let allSubject = res.data.data;
        //   //算法
        //   let map = {};
        //   allSubject.forEach(item => {
        //     let a = item.category,
        //       b = item.firstMajor,
        //       c = item.secondMajor,
        //       id = item.id,
        //       code = item.secondMajorCode;
        //     if (!map.hasOwnProperty(a)) map[a] = [];
        //     map[a].push({
        //       id: id,
        //       b: b,
        //       c: c,
        //       code: code
        //     });
        //   })
        //   let arr = [];
        //   let cnt = 1;
        //   for (let key in map) {
        //     arr.push({
        //       selfId: cnt + "",
        //       parentId: null,
        //       id: 0,
        //       category: key,
        //       firstMajor: '-',
        //       secondMajor: '-',
        //       secondMajorCode: '-'
        //     })
        //     map[key].forEach((val, idx) => {
        //       // console.log(map[key]);
        //       arr.push({
        //         selfId: cnt + "0" + idx,
        //         parentId: cnt + "",
        //         id: val.id,
        //         category: key,
        //         firstMajor: val.b,
        //         secondMajor: val.c,
        //         secondMajorCode: val.code
        //       });
        //     });
        //     cnt += 1;
        //   }
        //   //算法
        //   this.paperList = arr;
        //   this.loading = false;
        // }).catch(err => {
        //   this.$message.warning("服务器维护！");
        //   this.loading = false;
        // });
      },
      //搜索
      batchSearch() {
        this.getPaperList()
      },
      //搜索
      resetHandle() {
        this.search = {
          secondMajor: '',
        };
      },
      //添加账号
      singleInsert() {
        this.resetInsert();
        this.showEdit = true;
        this.isEdit = false;
      },
      //修改账号
      editHandle(row) {
        // const $table = this.$refs.schoolTable
        // $table.setActiveRow(row)
        console.log(row);
        this.showEdit = true;
        this.isEdit = true;
        this.formData = {
          id: row.id,
          category: row.category,
          firstMajor: row.firstMajor,
          secondMajor: row.secondMajor,
          secondMajorCode: row.secondMajorCode
        }
      },
      //批量添加账号
      handleChange(file, fileList) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        console.log(file);
        var reader = new FileReader(); //读取文件内容
        reader.readAsArrayBuffer(file.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
        reader.onload = (e) => {
          var dd = e.target.result;
          console.log(dd)
          var workbook = XLSX.read(dd, {
            type: 'buffer'
          });
          var sheetname = workbook.SheetNames[0]
          var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
          console.log(tables);
          var subjects = [];
          for (let i in tables) {
            let row = tables[i];
            var paps = {
              category: row['门类'],
              firstMajor: row['一级学科'],
              secondMajor: row['二级学科'],
              secondMajorCode: row['学科代码']
            };
            subjects.push(paps);
          };

          //发送请求
          let param = {
            subjectList: subjects
          };
          this.$axios.post('/course/add_course', param)
            .then(res => {
              if (res.data == 1) {
                this.$message.success("添加成功！");
                this.getAllSubject();
                this.showEdit = false;
              } else {
                this.$message.warning("添加失败！");
              }
              loading.close();
            }).catch(err => {
              loading.close();
              this.$message.warning("服务器维护！");

            })
        }
      },
      //提交添加的账号
      confirmInsert() {
        if (this.isEdit) {
          let param = this.formData;
          this.$axios.post('/course/alter_course', param)
            .then(res => {
              if (res.data == 1) {
                this.$message.success("修改成功！");
                this.getAllSubject();
                this.showEdit = false;
              } else {
                this.$message.warning("修改失败！");
              }
            }).catch(err => {
              this.$message.warning("服务器维护!");
            })
        } else {
          let param = {
            subjectList: [
              this.formData
            ]
          };
          this.$axios.post('/course/add_course', param)
            .then(res => {
              if (res.data == 1) {
                this.$message.success("添加成功！");
                this.getAllSubject();
                this.showEdit = false;
              } else {
                this.$message.warning("添加失败！");
              }
            }).catch(err => {
              this.$message.warning("服务器维护！");
            })
        }
      },
      //重置添加表单
      resetInsert() {
        this.formData = {
          category: '',
          firstMajor: '',
          secondMajor: '',
          secondMajorCode: ''
        };
      },

      //分页操作
      handlePageChange({
        currentPage,
        pageSize
      }) {
        this.page.currentPage = currentPage;
        this.page.pageSize = pageSize;
        this.getPaperList();
      },
      //每页多少数据改变
      handleChangePageSize(val) {
        this.page.pageSize = val;
        this.page.currentPage = 1;
        this.getPaperList();
      },
      firstPage() {
        this.page.currentPage = 1;
        this.getPaperList();
      },
      endPage() {
        this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
          .pageSize == 0 ? 0 : 1);
        this.getPaperList();
      },
      headerCellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        return "background-color:#e0e0e0f1"
      },
        fmMajorType({ cellValue }) {
            let item = this.majorTypeList.find(item => item.value === cellValue)
            return item ? item.label : ''
        },
        deleteHandle(row){
            console.log(row)
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true;
                let params = {
                    "subjectId": row.subjectId,
                }
                let subjectId = row.subjectId;
                console.log(params)
                this.$api.basicInfo.deleteSubject(subjectId).then(res => {
                    console.log(res);

                    this.loading = false;
                }).catch(err => {

                })
            })
        },
        insertHandle(){
            this.showAdd = true;
        },
        insertSubjectModelHideHandle(){
            this.$refs.subAddRef.clearValidate();
            this.subjectAdd={
                firstMajor:'',
                secondMajor:'',
                secondMajorCode:'',
                majorType:''
            }
        },
        // 点击确认按钮
        async cfmInsertSubjectHandle() {
            let isValid = await this.$refs.subAddRef.validate();
            console.log(isValid)
            if(isValid) return;
            console.log(this.subjectAdd);
            // return;
            // let params = new URLSearchParams();

            // params.append('schoolName', this.schoolName.name)
            this.$api.basicInfo.addSubject(this.subjectAdd)
                .then(res => {
                    if (res.data.code == 200){
                        this.showAdd = false;
                        this.getPaperList();
                        this.$message.success("添加成功");
                    }
                })
                .catch(err => {
                    this.$message.warning("服务器维护");
                })

        },
    },
    components: {

    }
  }
</script>

<style scoped>

</style>

<style>
  .subject-table .vxe-table--body-wrapper {
    height: calc(100vh - 210px);
  }
</style>